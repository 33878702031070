<template>
  
<div class="bg-default">
    <header class="header hidden">
        <div class="header-left">
            <a href="#">
                <img class="header-settings-icon" src="img/settings-icon.png" alt="Settings">
            </a>
        </div>
        <div class="header-center">
        </div>
        <div class="header-right">
            <a @click="exit()">
                <img class="header-settings-icon" src="img/exit-icon.png" alt="Exit">
            </a>
        </div>
    </header>
    <main class="main">
        <div class="container">
            <div class="summary">
                <div class="summary-left">
                    <div class="summary-left-card">
                        <div class="summary-left-card-img">
                            <img src="img/startmoney-icon.png" alt="Start money icon">
                        </div>
                        <div class="summary-left-card-title">
                            Induló<br>
                            tallérok
                        </div>
                        <div class="summary-left-card-number">
                            {{ogBalance}}
                        </div>
                    </div>
                    <div class="summary-left-card">
                        <div class="summary-left-card-img">
                            <img src="img/currency-bonus-icon.png" alt="Currency bonus icon">
                        </div>
                        <div class="summary-left-card-title">
                            Deviza váltás<br>
                            bónusz
                        </div>
                        <div class="summary-left-card-number">
                            {{devizaBonus ? $config.game.bonuses.exchangeBonus : '0'}}
                        </div>
                    </div>
                    <div class="summary-left-card">
                        <div class="summary-left-card-img">
                            <img src="img/graph-icon.png" alt="Graph icon">
                        </div>
                        <div class="summary-left-card-title">
                            Tőzsdei<br>
                            kereskedés
                        </div>
                        <div class="summary-left-card-number">
                            {{(Math.round(profit * $config.game.prices.valutaPurchaseRate)).toString()}}
                        </div>
                    </div>
                    <div class="summary-left-card">
                        <div class="summary-left-card-img">
                            <img src="img/best-bonus-icon.png" alt="Best bonus icon">
                        </div>
                        <div class="summary-left-card-title">
                            Legjobb kereskedő<br>
                            bónusz
                        </div>
                        <div v-if="this.groupId" class="summary-left-card-number">
                            {{this.visitorId == this.bestUserId ? $config.game.bonuses.bestTraderBonus.toString() : '0'}}
                        </div>
                        <div v-else class="summary-left-card-number">
                            0
                        </div>
                    </div>
                </div>
                <div class="summary-right">
                    <div class="summary-right-card">
                        <div class="summary-right-card-img">
                            <transition name="fade">
                                <div v-if="showAnimation" class="summary-right-card-img-coins">
                                    <div class="summary-right-card-img-coin summary-right-card-img-coin-1">
                                        <img src="img/coin-1.png" alt="Coin">
                                    </div>
                                    <div class="summary-right-card-img-coin summary-right-card-img-coin-4">
                                        <img src="img/coin-4.png" alt="Coin">
                                    </div>
                                    <div class="summary-right-card-img-coin summary-right-card-img-coin-2">
                                        <img src="img/coin-2.png" alt="Coin">
                                    </div>
                                    <div class="summary-right-card-img-coin summary-right-card-img-coin-3">
                                        <img src="img/coin-3.png" alt="Coin">
                                    </div>

                                </div>
                            </transition>

                            <div class="summary-right-card-img-treasure-chest">
                                <img src="img/treasure-chest.png" alt="Treasure chest">
                            </div>
                        </div>
                        <div class="summary-right-card-title">
                            Összesített<br>
                            egyenleged
                        </div>
                        <div class="summary-right-card-number">
                            <span>{{balance}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <footer class="footer">
    </footer>
</div>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
    computed: {
    ...mapGetters([
      "balance",
        "devizaBonus",
        "ogBalance",
        "profit",
        "groupId",
        "bestUserId",
        "actualGameTurnId",
        "stopGameTurnId",
        "visitorId",
    ])
  },
  data: function() {
      return {
          showAnimation: true,
      }
  },
    mounted() {
        setTimeout(() => {
            if(!this.groupId) return this.$router.push('end-video');
        },this.$config.settings.singlePlayerBalanceWaitingTime);
    },
    created() {
        if(this.devizaBonus) {
            this.addExchangeBonus();
            this.$store.dispatch('saveBalanceChange', {balance: this.$config.game.bonuses.exchangeBonus});
        }
        if(this.groupId) {
            if(this.visitorId == this.bestUserId) this.addTraderBonus();
        }
        // console.log("devb",this.devizaBonus);
    },
    methods: {
        addExchangeBonus() {
      let newBalance = this.balance + this.$config.game.bonuses.exchangeBonus;
      this.$store.commit("setBalance", newBalance);
    },
    addTraderBonus() {
        let newBalance = this.balance + this.$config.game.bonuses.bestTraderBonus;
      this.$store.commit("setBalance", newBalance);
    }

    },
    watch: {
        stopGameTurnId(newStop,oldStop) {
            if(newStop !== this.actualGameTurnId) {
                this.showAnimation = false;
                setTimeout(() => {
                this.$router.push('end-video');
            },this.$config.settings.groupBalanceWaitingTime)
            }
        }
    }
   
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>